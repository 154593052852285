import React, { useState, useEffect } from 'react';
import { MenuOutlined, HomeOutlined, NotificationOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Layout, Menu, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
const { Header, Sider, Content, Footer } = Layout;
export default function SideBar() {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const [selectedKey, setSelectedKey] = useState(localStorage.getItem('selectedKey') || '/dashboard');
    const [collapsed, setCollapsed] = useState(false);

    const { token: { colorBgContainer } } = theme.useToken();
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('selectedKey', selectedKey);
    }, [selectedKey]);


    const menu = [
        {
            key: '/home',
            icon: <HomeOutlined style={{ fontSize: 20 }} />,
            label: 'ໜ້າຫຼັກ',
        },
        {
            key: '/notification-list',
            icon: <NotificationOutlined style={{ fontSize: 20 }} />,
            label: 'ຈັດການຂໍ້ຄວາມເເຈ້ງເຕືອນ',
        },

    ]

    return (
        <div>
            <MenuOutlined onClick={showDrawer} style={{ color: "#FFFFFF", fontSize: 20 }} />
            <Drawer
                placement="left"
                onClose={onClose}
                open={open}
                size={'default'}
                width={'80%'}
                closeIcon={<CloseOutlined style={{ fontSize: '20px', color: '#fff' }} />}
                style={{
                    background: "#111827",
                    // width: "80%"
                }}>
                <Menu theme="dark" mode="inline" style={{ background: "#111827" }}
                    onClick={({ key }) => {
                        if (key == "/home") {
                            window.location.href = 'http://next.phongsavanhgroup.com';
                        } else {
                            navigate(key);
                            setSelectedKey(key)
                        }
                    }}
                    defaultSelectedKeys={[selectedKey]}
                    items={menu}
                />
            </Drawer>
        </div>
    )
}
