import React, { useState, useEffect } from 'react'
import {
  CheckCircleOutlined,
  PlusOutlined,
  EditOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  LeftOutlined,
  RightOutlined,
  HomeOutlined
} from '@ant-design/icons';
import { Space, Table, Tag, Button, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate'
import { useNotification } from '../../context/NotificationContext';
import { convertNotiStatus } from '../../constants';
import moment from 'moment';
import useWindowDimensions from '../../constants/useWindowDimensions';
import _ from 'lodash';
export default function NotificationList() {

  const navigate = useNavigate()
  const { width } = useWindowDimensions();
  const { selectEditData, setSelectEditData } = useNotification()
  const { notifications, loading } = useNotification()
  const [filteredResults, setFilteredResults] = useState(notifications || []);
  const LIMIT_PAGE = 15;
  const initialPageNumber = localStorage.getItem('MobileNoti-PageNumber') || '1'; // Use '1' as the default if not found
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [selectedStatus, setSelectedStatus] = useState(localStorage.getItem('selectedStatus') || 'All Status');

  const onNotificationDetail = (uid) => {
    navigate("/notification-detail/" + uid);
  }

  const onNotificationEdit = (uid) => {
    navigate("/create-notification/" + uid);
  }

  useEffect(() => {
    if (notifications) setFilteredResults(notifications || [])
  }, [notifications])

  useEffect(() => {
    // Update localStorage whenever selectedStatus changes
    localStorage.setItem('selectedStatus', selectedStatus);
  }, [selectedStatus]);

  useEffect(() => {
    if (selectedStatus === "All Status" || selectedStatus === "All" || selectedStatus === "ທັງໝົດ") {
      setFilteredResults(notifications || [])
    } else {
      const filtered = (notifications || []).filter((e) => e.status === selectedStatus);
      setFilteredResults(filtered);
    }
  }, [selectedStatus, notifications]);

  const columns = [
    {
      title: 'ລຳດັບ',
      dataIndex: 'number',
      key: 'number',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'ຫົວຂໍ້',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'ລາຍລະອຽດ',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'ວັນ,ເດືອນ,ປີ',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'ເວລາ',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'ສະຖານະ',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'ສ້າງໂດຍ',
      key: 'createBy',
      dataIndex: 'createBy',
    },
    {
      title: 'ຈັດການ',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => {
            setSelectEditData({ editMode: true, selectedData: record })
            onNotificationDetail(record?.key)
          }
          }>
            ເພີ່ມເຕີມ
          </Button>
          <Button
            type="primary"

            onClick={() => {
              navigate("/create-notification");
              setSelectEditData({ editMode: true, selectedData: record })
            }}

            style={{
              background: "green",
              display: record?.name === "approved" || record?.name === "canceled" || record?.name === "rejected" ? "none" : ""
            }}

            icon={<EditOutlined />}>
            ເເກ້ໄຂ
          </Button>
        </Space>
      ),
    },
  ];

  const data = filteredResults?.slice((pageNumber - 1) * LIMIT_PAGE, pageNumber * LIMIT_PAGE)?.map((e, index) => (
    {
      key: e?.uid,
      number: index + 1 + LIMIT_PAGE * (pageNumber - 1),
      title: e?.msg_title,
      description: _.truncate(e?.msg_body ?? "-", { length: 30, }),
      descriptionNow: e?.msg_body,
      date: moment(e?.schedule_notification).format("DD-MM-YYYY"),
      dateNow: moment(e?.schedule_notification).format("YYYY-MM-DD HH:mm:ss"),
      time: moment(e.schedule_notification).format("HH:mm"),
      createBy: e?.name,
      status: <Tag
        style={{
          height: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: 'center',
          fontSize: 14
        }} icon={e?.status === "waiting" ? <ClockCircleOutlined /> : e?.status === "canceled" ? <CloseCircleOutlined /> : e?.status === "rejected" ? <SyncOutlined spin /> : <CheckCircleOutlined />} color={convertNotiStatus(e?.status)}>
        {e?.status === "waiting" ? "ລໍຖ້າການອານຸມັດ" : e?.status === "canceled" ? "ປະຕິເສດ" : e?.status === "rejected" ? "ຂໍອານຸມັດ" : "ອານຸມັດສຳເລັດ"}
      </Tag>,
      name: e?.status
    }

  ))

  const handlePageChange = (e) => {
    const newPageNumber = e.selected + 1;
    localStorage.setItem('MobileNoti-PageNumber', newPageNumber);
    setPageNumber(newPageNumber);
  };

  const handleFliterClick = (status) => {
    // Update selectedStatus and set pageNumber to 1
    setSelectedStatus(status);
    setPageNumber(1);
  };

  const approvedCount = notifications?.filter(item => item.status === "approved");
  const watingCount = notifications?.filter(item => item.status === "waiting");
  const rejectCount = notifications?.filter(item => item.status === "rejected");
  const canceled = notifications?.filter(item => item.status === "canceled");

  // console.log("=======>selectedStatus", selectedStatus)

  return (
    <div>
      <div style={{
        display: "flex",
        justifyContent: width > 700 ? 'space-between' : '',
        flexDirection: width > 700 ? "" : 'column',
        alignItems: width > 700 ? "center" : "",
        paddingBottom: 20,
        // paddingTop: width > 700 ? "" : 20,
        padding: width > 700 ? "" : 20
      }}>

        <div style={{
          // paddingTop: width > 700 ? "" : 20,
          width: width > 700 ? "" : "100%",
          display: width > 700 ? "none" : "flex",
          alignItems: "center",
          justifyContent: "end"
        }}>
          {/* <Button style={{
            height: 40,
            width: width > 700 ? "" : '30%',
            display: width > 700 ? "none" : ""
          }} onClick={() => window.location.href = 'http://next.phongsavanhgroup.com'} icon={<HomeOutlined />}>
            ໜ້າຫຼັກ
          </Button> */}
          <Button
            type="primary"
            onClick={() => {
              navigate('/create-notification')
              setSelectEditData({ editMode: false })
            }}
            style={{ height: 40, width: "50%" }}
            icon={<PlusOutlined />}>
            ສ້າງເເຈ້ງເຕືອນ
          </Button>
        </div>
        <div style={{ fontSize: 16, paddingTop: width > 700 ? "" : 20 }}>ລາຍການເເຈ້ງເຕືອນ ທັງໝົດ ( {notifications?.length} ) ລາຍການ</div>
        <div style={{
          paddingTop: width > 700 ? "" : 20,
          display: width > 700 ? "flex" : 'flex',
          flexDirection: width > 700 ? "" : 'column',
        }}>
          <Button
            type={selectedStatus === "All Status" ? 'primary' : 'default'}
            onClick={() => handleFliterClick("All Status")}
            style={{ height: 40 }} >
            ທັງໝົດ
          </Button>
          &emsp;
          <div style={{ display: width > 700 ? "flex" : 'flex' }}>
            <Button
              type={selectedStatus === "waiting" ? 'primary' : 'default'}
              onClick={() => handleFliterClick("waiting")}
              style={{ height: 40, width: width > 700 ? "" : '100%' }}>
              ລໍຖ້າການອານຸມັດ <span style={{ paddingLeft: 10, fontSize: 18, fontWeight: 600 }}>{watingCount?.length}</span>
            </Button>
            &emsp;
            <Button
              type={selectedStatus === "rejected" ? 'primary' : 'default'}
              onClick={() => handleFliterClick("rejected")}
              style={{ height: 40, width: width > 700 ? "" : '100%' }}>
              ຂໍອານຸມັດ <span style={{ paddingLeft: 10, fontSize: 18, fontWeight: 600 }}>{rejectCount?.length}</span>
            </Button>
          </div>

          &emsp;
          <div style={{ display: width > 700 ? "flex" : 'flex' }}>
            <Button
              type={selectedStatus === "approved" ? 'primary' : 'default'}
              onClick={() => handleFliterClick("approved")}
              style={{ height: 40, width: width > 700 ? "" : '100%' }}
              icon={<CheckCircleOutlined />}>
              ອານຸມັດສຳເລັດ <span style={{ paddingLeft: 10, fontSize: 18, fontWeight: 600 }}>{approvedCount?.length}</span>
            </Button>
            &emsp;
            <Button
              type={selectedStatus === "canceled" ? 'primary' : 'default'}
              onClick={() => handleFliterClick("canceled")}
              style={{ height: 40, width: width > 700 ? "" : '100%' }}
              icon={<CloseCircleOutlined />}>
              ປະຕິເສດ <span style={{ paddingLeft: 10, fontSize: 18, fontWeight: 600 }}>{canceled?.length}</span>
            </Button>
          </div>
        </div>
        <div style={{
          paddingTop: width > 700 ? "" : 20,
          width: width > 700 ? "" : "100%",
          display: width > 700 ? "" : "none"
        }}>
          <Button
            type="primary"
            onClick={() => {
              navigate('/create-notification')
              setSelectEditData({ editMode: false })
            }}
            style={{ height: 40, width: "100%" }}
            icon={<PlusOutlined />}>
            ສ້າງເເຈ້ງເຕືອນ
          </Button>
        </div>
      </div>
      <Skeleton
        loading={loading}
        paragraph={{
          rows: 13,
        }}
        style={{
          marginTop: width > 700 ? 20 : "",
          paddingLeft: width > 700 ? "" : 20,
          paddingRight: width > 700 ? "" : 20,
        }}
        active Table>
        <div className="responsive-table" style={{ marginTop: width > 700 ? 20 : "" }}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
        <div style={{ justifyContent: "center", display: "flex", marginTop: 20 }}>
          <ReactPaginate
            previousLabel={<LeftOutlined />}
            onPageChange={handlePageChange}
            breakLabel="..."
            pageRangeDisplayed={2}
            pageCount={Math.ceil(filteredResults?.length / LIMIT_PAGE)}
            // pageRangeDisplayed={7}
            marginPagesDisplayed={2}
            renderOnZeroPageCount={null}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={pageNumber - 1}
            nextLabel={<RightOutlined />}
          />
        </div>
      </Skeleton>
    </div>
  )
}
