import React from 'react'
import { Outlet, useNavigate, Navigate } from "react-router-dom"
// import { isUserLogin } from '../const';
import MainLayout from '../components/MainLayout';
import useWindowDimensions from '../constants/useWindowDimensions';
import SideBar from '../components/SideBar';
import { VERSION } from '../constants';

export default function PrivateRoute() {
    const { width } = useWindowDimensions();
    // const isAuthenticated = isUserLogin()
    // const navigate = useNavigate()

    // if (!isAuthenticated) {
    return (

        <div>
            {
                width > 700 ?
                    <MainLayout>
                        <Outlet />
                    </MainLayout> :
                    <>
                        <div>
                            <div style={{
                                width: "100%",
                                height: 60,
                                background: '#111827',
                                position: "fixed",
                                zIndex: 1000,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingLeft: 20,
                                paddingRight: 20
                            }}>
                                <SideBar />
                                {/* <span style={{ color: "#FFFFFF" }}>PSVG NOTIFICATION</span> */}
                            </div>
                            <div style={{ paddingTop: 60 }}>
                                <Outlet />
                            </div>
                        </div>
                    </>
            }

        </div>
    );

    // return <Navigate to={'/login'} />
}
// }
