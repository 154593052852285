import React, { useState, useEffect } from 'react'
import { Button, Modal, Avatar, List, Checkbox, Alert } from 'antd';
import { useEmployee } from '../../context/EmployeeContext';

export default function AddMember({ show, handleClose, employee, setEmployee, selectedEmployees, setSelectedEmployees }) {
    const { employees, setEmployees } = useEmployee()
    const [newEmployee, setNewEmployee] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 7; // Number of items per page

    const handleCheckboxChange = (employeeId) => {
        const updatedSelectedEmployees = selectedEmployees.includes(employeeId)
            ? selectedEmployees.filter((id) => id !== employeeId)
            : [...selectedEmployees, employeeId];
        setSelectedEmployees(updatedSelectedEmployees);
    };

    const fetchDataForPage = (page) => {
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return employees.slice(startIndex, endIndex);
    };

    useEffect(() => {
        const newData = fetchDataForPage(currentPage);
        // You might need to modify your state structure based on your needs
        setNewEmployee(newData);
    }, [currentPage, employees]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleConfirm = () => {
        try {
            if (selectedEmployees <= 0) {
                Alert('ກະລຸນາເລືອກພະນັກງານ')
                return;
            }
            setEmployee([...selectedEmployees]);
            // setSelectedEmployees([])
            handleClose()
        } catch (error) {
            // console.log('Error: ', error)
            handleClose()
        }
    };


    // console.log("===========>newEmployee", newEmployee)
    // console.log("===========>selectedEmployees", selectedEmployees)

    return (
        <div>
            <Modal
                // title="Basic Modal"
                open={show}
                onOk={handleConfirm}
                centered
                closable={false}
                okText={'ຕົກລົງ'}
                cancelText={'ຍົກເລີກ'}
                transitionName={false}
                onCancel={handleClose}>
                <div style={{
                    fontSize: 18,
                    width: "100%",
                    height: "80vh",
                }}>
                    <List
                        pagination={{
                            position: 'bottom', // Change to 'top', 'both', or 'none' as needed
                            pageSize: pageSize,
                            total: employees.length,
                            onChange: handlePageChange,
                            current: currentPage,
                            showLessItems: true, // Show less page numbers
                            showSizeChanger: false, // Hide the "Items per page" dropdown
                        }}
                        dataSource={newEmployee}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Checkbox
                                                onChange={() => handleCheckboxChange(item)}
                                                checked={selectedEmployees.includes(item)}
                                            />
                                            <div style={{ width: 20 }}></div>
                                            <Avatar size={50} style={{ objectFit: "contain" }} src={item?.images} />
                                        </div>
                                    }
                                    title={<div>{item?.employee_name}&emsp;{item?.employee_surname}</div>}
                                    description={item?.employee_email}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </Modal>
        </div>
    )
}
