import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_URL } from '../api';
import axios from 'axios';

const EmployeeConext = createContext()

export default function EmployeeProvider({ children }) {

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false)

    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };

    const tokenString = getCookie('user');

    // Decode the URL-encoded string
    const decodedTokenString = decodeURIComponent(tokenString);

    const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${SERVER_URL + "employees-active"}`, {
                headers: {
                    Authorization: `Bearer ${tokenData?.token}`,
                    // Add other headers if needed
                },
            });

            setEmployees(response?.data?.results);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)

        }
    };

    // console.log("============>employee", employees)

    return (
        <EmployeeConext.Provider value={{ employees, setEmployees, loading }} >
            {children}
        </EmployeeConext.Provider>
    )
}

export function useEmployee() {
    return useContext(EmployeeConext)
}