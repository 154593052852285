import React, { useState, useEffect } from 'react'
import { CloseOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Collapse, Card, Breadcrumb, Button, DatePicker, Radio, List, Avatar, Space } from 'antd';
import axios from 'axios';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { useNotification } from '../../context/NotificationContext';
import useWindowDimensions from '../../constants/useWindowDimensions';
import AddMember from './AddMember';
import { SERVER_URL } from '../../api';
import Swal from 'sweetalert2';
import Form from 'react-bootstrap/Form';
import { Spinner } from 'react-bootstrap';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function CreateNotification() {

    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const { uid } = useParams();
    const [editMode, setEditMode] = useState(true)
    const [addMember, setAddmember] = useState(false)
    const [employee, setEmployee] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [eUsing, setEUsing] = useState(true)
    const [value, setValue] = useState(1);
    const [date, setDate] = useState()
    const { selectEditData, setSelectEditData, fetchData } = useNotification()
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);
    const [msTitle, setMsTitle] = useState()
    const [msDescription, setMsdescription] = useState()


    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };

    const tokenString = getCookie('user');

    // Decode the URL-encoded string
    const decodedTokenString = decodeURIComponent(tokenString);

    const tokenData = decodedTokenString ? JSON?.parse(decodedTokenString) : null;

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    useEffect(() => {
        if (selectEditData) {
            setEditMode(selectEditData?.editMode)
        } else {
            setEditMode(false)
        }
    }, [selectEditData])

    useEffect(() => {
        if (editMode === true) {
            setMsTitle(selectEditData?.selectedData?.title)
            setMsdescription(selectEditData?.selectedData?.descriptionNow)
            const formattedDate = dayjs(selectEditData?.selectedData?.dateNow);
            // const formatted = formattedDate.utcOffset(7).format("YYYY-MM-DD HH:mm:ss");
            setSelectedDate(formattedDate);
        }
        else {
            setSelectEditData('')
            setSelectedDate('')
        }
    }, [editMode])


    useEffect(() => {
        if (eUsing === true) {
            setEmployee([])
        }
    }, [eUsing])


    const onChange = (e) => {
        // console.log('radio checked', e.target.value);
        setValue(e.target.value);
        // Update eUsing based on the selected value
        setEUsing(e.target.value === 1);
    };

    const handleDelete = (index) => {
        const updatedEmployee = [...employee];
        const updatedselectedEmployee = [...selectedEmployees];
        updatedselectedEmployee.splice(index, 1);
        updatedEmployee.splice(index, 1);
        setEmployee(updatedEmployee);
        setSelectedEmployees(updatedselectedEmployee)
    };

    const onCreateNotification = async (values) => {

        const apiUrl = `${SERVER_URL + "notifications/list/insert"}`;
        // const apiUrl = `https://api.phongsavanhgroup.com/notifications/list/insert`;

        setLoading(true)
        try {
            const apiResponse = await axios.post(apiUrl, values, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                },
            });

            Swal.fire({
                position: "top-center",
                icon: "success",
                title: "ບັນທຶກສຳເລັດ",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
            });

            fetchData()
            setEmployee([])
            navigate(-1)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }


    const onUpdateNotification = async (values) => {

        const apiUrl = `${SERVER_URL + "notifications/list/update/" + selectEditData?.selectedData?.key}`;

        setLoading(true)
        try {

            const apiResponse = await axios.put(apiUrl, values, {
                headers: {
                    'Authorization': `Bearer ${tokenData?.token}`,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                },
            });

            Swal.fire({
                position: "top-center",
                icon: "success",
                title: "ອັບເດດຂໍ້ມູນສຳເລັດ",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
            });

            fetchData()
            setEmployee([])
            navigate(-1)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onCancel = () => {
        setEUsing(true)
        setSelectedDate('')
        setMsTitle('')
        setMsdescription('')
        setEmployee([])
    }


    function onChangeDate(date, dateString) {
        // Parse the selected date in local time zone
        const parsedDate = dayjs(dateString);

        // Convert to Bangkok time (UTC+7)
        const bangkokTime = parsedDate.utcOffset(7);
        bangkokTime.format("YYYY-MM-DD HH:mm:ss")
        // Set the selected date in the new time zone
        setSelectedDate(bangkokTime);
        // console.log("=========>parsedDate", bangkokTime.format("YYYY-MM-DD HH:mm:ss"));
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                title: msTitle,
                message: msDescription,
                all: eUsing,
                employees: employee?.filter(em => em?.employee_id).map((obj) => (obj.employee_id)),
                schedule: selectedDate
            }}
            validate={(values) => {
                const errors = {};
                if (!msTitle) {
                    errors.title = 'ກະລຸນາປ້ອນຊື່ຫົວຂໍ້ !';
                }
                if (!msDescription) {
                    errors.message = 'ກະລຸນາປ້ອນລາຍລະອຽດ !';
                }
                if (!selectedDate) {
                    errors.schedule = 'ກະລຸນາເລືອກວັນທີ ເເລະ ເວລາ !';
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true); // Set form submission status to true, if applicable
                    // Format the selectedDate before saving
                    const parsedDate = dayjs(values.schedule);
                    const formattedDate = parsedDate.utcOffset(7).format("YYYY-MM-DD HH:mm:ss");

                    // Update the values with the formatted date
                    values.schedule = formattedDate;

                    if (editMode === true) {
                        await onUpdateNotification(values);
                        // Handle success or redirect after updating notification
                    } else {
                        await onCreateNotification(values);
                        // Handle success or redirect after creating new notification
                    }
                } catch (error) {
                    // Handle error, e.g., display an error message
                    console.error('Error:', error.message);
                } finally {
                    setSubmitting(false); // Set form submission status to false, if applicable
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => (
                <div style={{ padding: width > 700 ? "" : 20 }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: 20,
                        alignItems: "center",
                        width: "100%",
                    }}>
                        <Breadcrumb
                            style={{ fontSize: 16 }}
                            items={[
                                {
                                    title: (
                                        <div style={{ cursor: "pointer" }}>
                                            <span onClick={() => navigate('/notification-list')}>ກັບຄືນ</span>
                                        </div>
                                    ),
                                },
                                {
                                    title: selectEditData?.editMode === true ? "ເເກ້ໄຂເເຈ້ງເຕືອນ" : 'ສ້າງເເຈ້ງເຕືອນ',
                                },
                            ]}
                        />

                        <div style={{ display: width > 700 ? "" : "none" }}>
                            <Button
                                type="primary"
                                // loading={loading}
                                style={{ marginRight: 10, height: 40 }}
                                onClick={() => handleSubmit()}
                                icon={<CheckOutlined />}>
                                {loading ? <Spinner size='sm' /> : 'ບັນທຶກ'}
                            </Button>

                            <Button
                                // type="primary"
                                style={{ height: 40 }}
                                onClick={() => {
                                    if (editMode === true) {
                                        navigate(-1)
                                    } else {
                                        onCancel()
                                    }
                                }}
                                icon={<CloseOutlined />}>
                                ຍົກເລີກ
                            </Button>
                        </div>
                    </div>
                    <div style={{ display: width > 700 ? "none" : "flex", justifyContent: "end" }}>
                        <Button
                            type="primary"
                            // loading={loading}
                            style={{ marginRight: 10, height: 40 }}
                            onClick={() => handleSubmit()}
                            icon={<CheckOutlined />}>
                            {loading ? <Spinner size='sm' /> : 'ບັນທຶກ'}
                        </Button>

                        <Button
                            // type="primary"
                            style={{ height: 40 }}
                            onClick={() => {
                                if (editMode === true) {
                                    navigate(-1)
                                } else {
                                    onCancel()
                                }
                            }}
                            icon={<CloseOutlined />}>
                            ຍົກເລີກ
                        </Button>
                    </div>

                    <Card
                        style={{
                            width: '100%',
                            marginTop: width > 700 ? "" : 20
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: width > 700 ? "" : "column" }}>
                            <div style={{ width: width > 700 ? "50%" : "100%" }}>
                                <div>
                                    <Collapse
                                        defaultActiveKey={['1']}
                                        items={[{
                                            key: '1',
                                            label: 'ຫົວຂໍ້',
                                            children: (
                                                <div>
                                                    <Form.Control
                                                        name='title'
                                                        value={msTitle}
                                                        onChange={(e) => setMsTitle(e.target.value)}
                                                        isInvalid={errors.title}
                                                        as="textarea" rows={4} />
                                                    {touched.title && errors.title && (
                                                        <div style={{ color: "red" }}>{errors.title}</div>
                                                    )}
                                                </div>
                                            ),
                                        }]}
                                        style={{ borderBottom: "none" }} />
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <Collapse
                                        defaultActiveKey={['1']}
                                        items={
                                            [
                                                {
                                                    key: '1',
                                                    label: 'ລາຍລະອຽດ',
                                                    children:
                                                        <div>
                                                            <Form.Control
                                                                name='message'
                                                                value={msDescription}
                                                                onChange={(e) => setMsdescription(e.target.value)}
                                                                isInvalid={errors.message}
                                                                as="textarea"
                                                                rows={4} />
                                                            {touched.message && errors.message && (
                                                                <div style={{ color: "red" }}>{errors.message}</div>
                                                            )}
                                                        </div>,
                                                },
                                            ]
                                        } />
                                </div>
                            </div>
                            <div style={{ width: 20 }}></div>
                            <div style={{ width: width > 700 ? "50%" : "100%" }}>
                                <div style={{ marginTop: width > 700 ? "" : 20 }}>
                                    <Collapse
                                        defaultActiveKey={['1']}
                                        items={
                                            [
                                                {
                                                    key: '1',
                                                    label: 'ກຳນົດວັນທີເເຈ້ງເຕືອນ',
                                                    children:
                                                        <div style={{ width: "100%" }}>
                                                            <DatePicker
                                                                showTime
                                                                format="YYYY-MM-DD HH:mm:ss"
                                                                valueFormat="YYYY-MM-DD HH:mm:ss"
                                                                value={selectedDate}
                                                                onChange={onChangeDate}
                                                                placeholder='ເລຶອກວັນທີ ເເລະ ເວລາ'
                                                                allowClear={false} // Disable the clear icon
                                                                style={{ width: "100%" }}
                                                            />
                                                            {/* <Form.Control
                                                                type='datetime'
                                                            /> */}
                                                            {touched.schedule && errors.schedule && (
                                                                <div style={{ color: "red" }}>{errors.schedule}</div>
                                                            )}
                                                        </div>
                                                    ,

                                                },
                                            ]
                                        } />
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <Collapse
                                        defaultActiveKey={['1']}
                                        items={[
                                            {
                                                key: '1',
                                                label: 'ກຳນົດຜູ້ໄດ້ຮັບເເຈ້ງເຕືອນ',
                                                children:
                                                    <div>
                                                        <Radio.Group onChange={onChange} value={value}>
                                                            <Radio value={1}>ໝົດທຸກຄົນ</Radio>
                                                            <Radio value={2}>ສະເພາະຄົນທີເລືອກ</Radio>
                                                        </Radio.Group>
                                                        <br />
                                                        <div>
                                                            <Button
                                                                onClick={() => setAddmember(true)}
                                                                type="primary"
                                                                style={{ marginTop: 20, display: value === 1 ? "none" : "" }}>
                                                                + ເພີ່ມ
                                                            </Button>
                                                            <span style={{ paddingLeft: 20, display: value === 1 ? "none" : "" }}>ຈຳນວນ ( {employee?.length} ) ທ່ານ</span>
                                                        </div>
                                                        <List
                                                            style={{ marginTop: 20, display: value === 1 ? "none" : "" }}
                                                            itemLayout="horizontal"
                                                            dataSource={employee}
                                                            renderItem={(item, index) => (
                                                                <List.Item>
                                                                    <List.Item.Meta
                                                                        avatar={<Avatar src={item?.images} />}
                                                                        title={<div>{item?.employee_name}&emsp;{item?.employee_surname}</div>}
                                                                    // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                                                    />
                                                                    <div>
                                                                        <Button icon={<DeleteOutlined />} onClick={() => handleDelete(index)}>
                                                                            ລຶບ
                                                                        </Button>
                                                                    </div>
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </div>,
                                            },
                                        ]} />
                                </div>
                            </div>
                        </div>
                    </Card>
                    <AddMember
                        show={addMember}
                        handleClose={() => setAddmember(false)}
                        employee={employee}
                        setEmployee={setEmployee}
                        selectedEmployees={selectedEmployees}
                        setSelectedEmployees={setSelectedEmployees}
                    />
                </div >

            )}
        </Formik>
    )
}
